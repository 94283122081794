import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { Message as TwilioMessage } from '@twilio/conversations';
import { User } from '@models/user.model';
import { ApiService } from '@services/api.service';
import {AlertService} from "@services/alert.service";
import { Mind2Message } from '@app/models/message.model';

@Injectable({
	providedIn: 'root',
})
export class UtilsService {
	constructor /*private router: Router,*/(private alertService: AlertService) {}

	scrollToBottom(content: IonContent, duration: number = 10000) {
		setTimeout(async () => await content.scrollToBottom(), 300);
	}

	getUserPhoto(photo?: string): string {
		let photoPath
		if (photo == null || photo == undefined){
			photoPath = '../../../../assets/person-circle-outline.svg';
		} else{
			photoPath = `${ApiService.BASE_URL}/v2/users/photo/${photo}/sm`;
		}

		return photoPath;
	}

	getContentImage(image?: string, size? : string): string {
		let imagePath
		switch (size){
			case 'sm':
				imagePath = `${ApiService.BASE_URL}/content/image/${image}/sm`;
				break;
			case 'md':
				imagePath = `${ApiService.BASE_URL}/content/image/${image}/md`;
				break;
			case 'lg':
				imagePath = `${ApiService.BASE_URL}/content/image/${image}/lg`;
				break;
		}
		return imagePath;
	}

	getSpecialtyImage(image?: string, size? : string): string {
		let imagePath
		switch (size){
			case 'sm':
				imagePath = `${ApiService.BASE_URL}/specialty/image/${image}/sm`;
				break;
			case 'md':
				imagePath = `${ApiService.BASE_URL}/specialty/image/${image}/md`;
				break;
			case 'lg':
				imagePath = `${ApiService.BASE_URL}/specialty/image/${image}/lg`;
				break;
		}
		return imagePath;
	}

	formatDate(date: Date): string {
		return date.toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: '2-digit', weekday: 'long' });
	}

	formatDateTwo(date: string): string {
		let [year, month, day] = date.split('-');
		return `${day}-${month}-${year}`;
	}

	formatTime(date: Date): string {
		return date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit', hour12: true });
	}

	formatTimeString(date: string): string {
		return date.slice(0, -3);
	}

	formatMessage(message: MESSAGE_TYPE, localUser: User, remoteUser: User): Mind2Message {
		if(message != undefined){
			let isLocalUser = message.author === localUser.email;
			return {
				content: message.body,
				emisor: isLocalUser ? 'emisor' : 'receptor',
				name: isLocalUser ? localUser.full_name : remoteUser.full_name,
				photo: isLocalUser ? localUser.photo : remoteUser.photo,
				dateCreated: message.dateCreated,
				date: this.formatDate(message.dateCreated),
				time: this.formatTime(message.dateCreated),
			};
		}
	}

	formatMessageSupport(message: TwilioMessage, localUser: User, full_name, photo) {
		let isLocalUser = message.author == localUser.email;
		return {
			content: message.body,
			emisor: isLocalUser ? 'emisor' : 'receptor',
			name: isLocalUser ? localUser.full_name : full_name,
			photo: isLocalUser ? localUser.photo : photo,
			date: this.formatDate(message.dateCreated),
			time: this.formatTime(message.dateCreated),
		};
	}

	currentTime(startTime: number): string {
		const now = Date.now();
		const difference = now - startTime;
		const seconds = Math.floor((difference / 1000) % 60);
		const minutes = Math.floor((difference / (1000 * 60)) % 60);
		const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
		return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
			.toString()
			.padStart(2, '0')}`;
	}

	loadFile(event: any, mediapipeValidation?: 'image' | 'video' | 'audio'): Observable<string> {
		return new Observable<string>((observer) => {
			const archive: File = event.target.files[0];

			if (mediapipeValidation && !archive.type.startsWith(mediapipeValidation)) {
				observer.error(new Error('El archivo debe ser ' + mediapipeValidation));
				console.log('archivo incorrecto');
			}

			if(archive.size <= 5 * 1024 * 1024){
				if (archive) {
					const reader = new FileReader();

					reader.onload = (e: any) => {
						observer.next(e.target.result.split(';base64,').pop());
						observer.complete();
					};

					reader.readAsDataURL(archive);
				}else{
					console.log('archivo incorrecto')
				}
			}else{
				this.imageNoLoad();
			}

		});
	}
	imageNoLoad() {
		this.alertService.presentAlertAdvise('¡Hubo un problema!', 'El tamaño de tu imagen supera el peso permitido (5MB)');
	}

	detectMobile(): boolean {
		const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		const mobileWidth = 900; // Puedes ajustar este valor según tus necesidades

		return screenWidth < mobileWidth;
	}
}

export interface MESSAGE_TYPE {
	author: string;
	body: string;
	dateCreated: Date;
}
